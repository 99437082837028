import '../Styles/App.css';
import React from 'react';

export default class OtherProjectsPage extends React.Component {
   render() {
      return (
         <div className='Page-container'>
            <div className='Page-content-block'>
               <p className='Page-content-block-text'>
                  One of my smaller "projects" was less of a formal project, but more practice. I took part in a 100 Days of
                  Code challenge. This saw me coding something, regardless of what, every day for 100 days straight.
                  My strategy was to stick to one project for a few days at a time, usually aiming to implement one
                  feature or set of features depending on the scopes of said features. This helped me not only
                  diversify the skills I was practicing, but also avoid any sort of burn-out.
                  <br/><br/>
                  The closest I came to reaching the point was when I let myself slip into the infamous tutorial hell. 
                  I was following an absolutely <a href='https://www.youtube.com/playlist?list=PLFt_AvWsXl0eBW2EiBtl_sxmDtSgZBxB3' className='App-link'>tutorial series by Sebastian Lague</a> about procedural world generation using heightmaps. 
                  The way he approached the problem taught me numerous lessons related to composition, scope, and of course the math behind procedural generation using noise. 
                  While each of these things was fascinating in its own right, following episode after episode
                  became not only repetitive, but quickly became frustrating when I ran in to an issue that
                  day-after-day I could not manage to solve. 
                  I eventually chose to abandon the tutorial series after reflecting on the amount I'd learned from the series versus what was left to learn from it, and weighing whether or not it was worth the time I was pouring in to debug an issue with the vertex edges of the chunk system not aligning.
                  <br/><br/>
                  Such reflection was something I tried, and I believe greatly enhanced throughout those 100 days.
                  By posting on social media, there was some external pressure to deliver every day, not just with
                  some sort of screenshot of my work, but also with insights into what I'd done that day. The
                  practice was a very healthy one, and one I'd like to attempt in at least some form again.
               </p>
            </div>
            <div className="Project-list-items Other">
               <a className="Project-list-item Other" href="/projects/alpha-in-trouterspace">Alpha In Trouterspace</a>
               <a className="Project-list-item Other" href="/projects/defender">Defender</a>
            </div>
         </div>
      );
   }
}