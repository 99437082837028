import '../Styles/App.css';
import React from 'react';

export default class ProjectEmbed extends React.Component {
   render() {
      return (
         <div className="Page-container Project">
            <div className="Project-embed">
               {this.props.embed}
            </div>
            <div className="Project-description">
               {this.props.description}
            </div>
         </div>
      );
   }
}