import React from 'react';

export default class NotFoundPage extends React.Component {
    render() {
        return (
            <div className="Page-container">
                <h1>Page not found</h1>
                <p>
                    Sorry, but the page you are looking for does not exist.
                    <br />
                    <a href="/home" className="App-link">Go back to the home page</a>
                </p>
            </div>
        );
    }
}