import React from 'react';

export default class NotFoundPage extends React.Component {
    render() {
        return (
            <div className="Page-container">
                <h1>Project not found</h1>
                <p>
                    Sorry, but the project you are looking for does not exist.
                    <br/>
                    <a href="/projects" className="App-link">Go back to the project list</a>
                </p>
            </div>
        );
    }
}