import React from 'react';
import ProjectsPage from '../components/ProjectEmbed';
import Iframe from 'react-iframe';

var width = window.innerWidth*.8;
var height = window.innerHeight;

export default class TrouterspacePage extends React.Component {
   render() {
      return(
         <ProjectsPage name="alpha_in_trouterspace" description={
            <div className="Page-content-block-wide Project">
                <h2 className="Project-h2">
                    Controls
                </h2>
                <p className="Project-description-paragraph">
                    SD - Move left and right
                    <br/>
                    W - Jump
                    <br/>
                    E - interact
                    <br/>
                    LMB - Shoot / RMB - Rocket
                </p>
                <h2 className="Project-h2">
                    The Process
                </h2>
                <p className="Project-description-paragraph">
                    This was one of the first projects I worked on while attending Full Sail. 
                    In an early professional development class, an example of a project going above and beyond was making a game based on the research the project was about.
                    My group took the leap of faith to decide to try it, even given the brief time frame.
                    <br/>
                    I had an incredible group, credited below, who helped to do everything I normally would not have in
                    a project. This project would not be as visually interesting, or honestly as interesting in any way
                    if not for the help of my group.
                    <br/><br/>
                    From the very get go, we were all on the same page: this was to be a game prototype. To us this
                    meant a couple of things. For one, it wouldn't be anywhere near perfect, or maybe even or best work
                    at times. Two, we couldn't add everything we wanted, even if it was reasonable. With our clear cut
                    month to work on the project, we set to work immediately.
                    <br/>
                    My process began with nailing down player movement. Being a Metroidvania, and thus a platformer, we
                    wanted to ensure that our movement felt right. While this is a great thing to focus on for a longer
                    project, I lost sight of the scope of the game in working on movement. What I created wasn't an
                    incredibly designer-friendly tool, but it was highly customizable. In the future, I'd like to
                    recreate the system with some more time in order to create a more user-friendly interface to
                    customize the different aspects of the jump and run. There were many variables to tweak the movement
                    of the player, but even with good naming conventions, they weren't very intuitive to work with. When
                    I remake this system, I'd like to have individual types of movement, such as the jump, or run,
                    dependent on only a single graph. This would require more complex code on my part, but if done
                    right, could make anyone not familiar with the code able to fine-tune the movement.
                    <br/>
                    After realizing I'd spent too much time on the movement system, I hastily moved on to begin
                    implementing the character interactions. Given the time I had, I only implemented a simple save
                    system which saves the player's progress in the Player Preferences provided by Unity. This is an
                    awful way to accomplish this, but it was significantly simpler than programming a more robust save
                    system for a small prototype I was only guaranteed to be working on for a month. On the topic of
                    interactions, this was the biggest example where I did not properly generalize components where I
                    could have.
                    <br/>
                    My goal in this project was to make more extensive use of event based architecture in order to make
                    my code not only cleaner, but also more easily extensible in case my group decided to continue work
                    on the project. However, I missed the mark when it came to make interactables follow the same
                    design. I instead programmed two separate classes since the later interactable was implemented in
                    the final hours of the project's development before it was showcased for the class project. This was
                    a simple fix that to me highlighted the importance of planning out your game architecture,
                    regardless of scale.
                    <br/>
                    The most interesting part of this project by far and away was my attempt at making a simple enemy
                    AI. For the most part, this was a success, however it'd be more accurate to call the entire process
                    a great learning experience rather than a success. The enemy agro is highly buggy, the pathing is
                    questionable at best, and it is all very hard-coded. If I were to go about this again, I'd make
                    better use of gizmos to visualize the patrol paths of the enemies, and spend more time working on
                    enemy pathing towards the player so that they wouldn't simply blindly run after the player. Another
                    change I desperately wanted to make during the project was to implement a visual state-machine.
                    Unfortunately, the added complexity since I had no prior experience beyond Unity's Animators meant
                    the idea had to be dropped.
                    <br/>
                    More successful than the enemy AI in my opinion, was our boss fight. The boss fight feels good for
                    a first attempt at programming a boss fight. The glaring issue with it, ironically given that
                    phrasing, was readability. The boss had no lead up animations because of the time crunch
                    implementing it at the end, and the movements were literal teleportation, giving the player no real
                    time to react. Stop gaps were put in, such as pauses at the beginning of different phases. However,
                    the underlying issue remained of readability, something that also points towards a need for better
                    prior planning.
                </p>
                <h2 className="Project-h2">
                    Our Team
                </h2>
                <p className="Project-description-paragraph">

                    UI Programming: Jeffrey Mathewson{/*<a className="App-link" href={"#"}>Jeffrey Mathewson</a>*/}<br/>
                    Concept and UI Art: <a className="App-link" href={"https://www.instagram.com/confusedkya/"}>Kya Rodriguez</a><br/>
                    Character Art: Daniel Ramos{/*<a className="App-link" href={"#"}>Daniel Ramos</a>*/}<br/>
                    Environment Art: Jake Wilcox{/*<a className="App-link" href={"#"}>Jake Wilcox</a>*/}<br/>
                    Gameplay Programming: <a className="App-link" href={"/about"}>Jonah McConnell</a>
                </p>
            </div>
        }
            embed={
                <Iframe url="https://jonahmc-bucket.s3.amazonaws.com/Metroidvania+Web+Build/index.html"
                        width={width + "px"}
                        height={height + "px"}
                        id="frameID"
                        className="frameClass"
                        display="initial"
                        position="relative"
                        allowFullScreen="yes"/>
            }/>
        );
   }
}