import '../Styles/App.css';
import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faAddressCard, faArrowAltCircleRight} from '@fortawesome/free-regular-svg-icons';
import { faHome, faBriefcase } from '@fortawesome/free-solid-svg-icons';

export default class Navbar extends React.Component {
   constructor(props) {
      super(props);

      this.state = {
         expanded: false,
         navbarClass: "navbar"
      }

      this.expand = this.expand.bind(this);
   }

   expand(){
      this.setState(prevState => ({
         expanded: !prevState.expanded,
         navbarClass: prevState.expanded ? "navbar" : "navbar expanded"
      }));
   }

   render() {
      return (
         <nav className={this.state.navbarClass}>
            <ul className="navbar-nav">
               <li className="nav-item">
                  <a href={window.origin} className="nav-link">
                  <FontAwesomeIcon icon={faHome} size="2x" color="white"/>
                  <span className="link-text">Home</span>
                  </a>
               </li>

               <li className="nav-item">
                  <a href={window.origin + "/about"} className="nav-link">
                  <FontAwesomeIcon icon={faAddressCard} size="2x" color="white"/>
                  <span className="link-text">About</span>
                  </a>
               </li>

               <li className="nav-item">
                  <a href={window.origin + "/projects"} className="nav-link">
                  <FontAwesomeIcon icon={faBriefcase} size="2x" color="white"/>
                  <span className="link-text">Portfolio</span>
                  </a>
               </li>

               <li className="nav-item expand">
                  <div onClick={this.expand} className="nav-link expand">
                     <FontAwesomeIcon icon={faArrowAltCircleRight} size="2x" color="white" className="expand"/>
                     <span className="link-text">Close</span>
                  </div>
               </li>
            </ul>
            </nav>
      );
   }
}