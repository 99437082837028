import React from 'react';
import ProjectsPage from '../components/ProjectEmbed';
import Iframe from 'react-iframe';

var width = window.innerWidth*.8;
var height = window.innerHeight;

export default class GalanariusPage extends React.Component {
   render() {
      return(
         <ProjectsPage name="galanarius" description={
            <div className="Page-content-block-wide Project">
                <h2 className="Project-h2">
                    Controls
                </h2>
                <p className="Project-description-paragraph">
                    While in the expanded view of the gridded map:
                    <br/><br/>
                    B - Open/Close buildings menu
                    <br/>
                    Space - Pause/Unpause tick time for buildings
                    <br/>
                    Esc - Return to previous view (NOTE: does not work in full screen)
                </p>
                <h2 className="Project-h2">
                    My Process
                </h2>
                <p className="Project-description-paragraph">
                    Galanarius is the beginning of a large scale project, meant to be a deeper simulation than my other games. It
                    generates data about the entire galaxy, with the scale as follows of galaxy, sector (a grid like structure
                    dividing the galaxy), planets, planetoids, and nodes. This project was never meant to be completed, but instead
                    was meant to introduce me to persistence, and thereby how to load and save data, basic generation (using purely
                    random numbers, and very little weighting of the odds via equations), and how to manage data in larger systems.
                    <br/><br/>
                    The first two goals were easily met, as they were very concrete, and easily obtained through a handful of
                    tutorials and experimentation. Implementation was a bit tricky, as Unity doesn't explicitly allow for
                    serialization of Game Objects. However, this was relatively easily circumvented by creating wrapper classes
                    specifically to store, and allow access to the data a class would normally store itself using properties.
                    <br/><br/>
                    Goal three however, managing data in larger systems, was a much more abstract concept, and frankly one I would
                    say I failed at. While the data can successfully be saved and loaded to allow for persistence, the
                    implementation for this created a mess in the project directory. To facilitate the wrapper classes, the number
                    of classes in the project effectively doubled. If was to go about a project like this, I would consider a more
                    data driven than object oriented design in order to better keep the code clean and expandable.
                    <br/><br/>
                    All in all, I am quite proud of the progress on this project, as I even added features I didn't expect to get to
                    in this experiment, such as the building system used to interact with the game at a node scale. Given more time,
                    I would've liked to create better interaction between scales, and add an actual menu. Everything considered,
                    since it was always meant to be a prototype and nothing more, I'm not concerned with the way the UI appeared.
                    I'd love to work on this project again in the future, and use what I learn to make a better, cleaner version.
                </p>
            </div>
        }
            embed={
                <Iframe url="https://jonahmc-bucket.s3.amazonaws.com/Galanarius+WebGL/index.html"
                        width={width + "px"}
                        height={height + "px"}
                        id="frameID"
                        className="frameClass"
                        display="initial"
                        position="relative"
                        allowFullScreen="yes"/>
            }/>
        );
   }
}