import '../Styles/App.css';
import PlanetariumImage from '../images/planetarium/Gameplay.png';
import CryptidConquestImage from '../images/cryptid-conquest/Gameplay.png';
import React from 'react';

export default class ProjectsList extends React.Component {
   render() {
      return (
         <div className="Page-container">
            <div className="Project-list-items">
               <a className="Project-list-item" href="/projects/planetarium">
                  <span className="Image-combo">
                     <p>Planetarium</p>
                     <img src={PlanetariumImage} alt='Planetarium Icon' className='Block-Image Horizontal'/>
                  </span>
               </a>
               <a className="Project-list-item" href="/projects/cryptid-conquest">
                  <span className="Image-combo">
                     <p>Cryptid Conquest</p>
                     <img src={CryptidConquestImage} alt='Cryptid Conquest Icon' className='Block-Image Horizontal'/>
                  </span>
               </a>
               <a className="Project-list-item" href="/projects/cryptid-conquest">Star Penguin</a>
               <a className="Project-list-item" href="/projects/other">Other</a>
            </div>
         </div>
      );
   }
}