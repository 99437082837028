/* eslint-disable jsx-a11y/img-redundant-alt */
import '../Styles/App.css'
import React from 'react';

export default class TextBlock extends React.Component {
   render() {
      return (
         <span className='Block-Wrapper Vertical'>
            <span className='Block-Header'>
               {this.props.Header}
            </span>
            <span className='Block-Body Centered'>
               {this.props.Body}
            </span>
         </span>
      );
   }
}