import React from 'react';
import '../Styles/App.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faGithub, faLinkedin, faPatreon} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
faInstagram.iconName = 'instagram';
faGithub.iconName = 'github';
faLinkedin.iconName = 'linkedin';
faPatreon.iconName = 'patreon';
faEnvelope.iconName = 'email';

export default class LinkInBioPage extends React.Component {
   render() {
      return (
         <div className="Page-container">
            {linkCard(faInstagram, "instagram")}
            <br/>
            {linkCard(faGithub, "github")}
            <br/>
            {linkCard(faLinkedin, "linkedin")}
            <br/>
            {linkCard(faEnvelope, "email")}
         </div>
      );
   }
}

function linkCard(icon, link){
   return (
      <div className="Link-container">
         <a href={window.location.origin+ "/" + link} className="Link">
             <FontAwesomeIcon icon={icon} size="2x" color="white" className="Link-icon"></FontAwesomeIcon>
             <h3 className="Link-name">
                 {link.substring(0,1).toUpperCase() + link.substring(1, link.length)}
             </h3>
         </a>
      </div>
   );
}