import '../Styles/App.css';
import TextBlock from '../components/TextBlock';
import ImageBlock from '../components/ImageBlock';
import RendererSampleImage from '../images/star-penguin/RendererSample.png'
import React from 'react';

export default class StarPenguin extends React.Component {
   render() {
      return (
         <div className="Page-container Project">
            <TextBlock
            Header={
               <span>
                  Star Penguin
               </span>
            } 
            Body={
               <span>
                  Space Sim: Vulkan, FLECs & Git
               </span>
            }/>
            <br/>
            <ImageBlock Reversed={false} BlockImage={RendererSampleImage} BlockImageType='Horizontal' Body={
             <p>
               
             </p>  
            }/>
            <br/>
            <ImageBlock Reversed={true} BlockImage={RendererSampleImage} BlockImageType='Horizontal' Body={
             <p>
               
             </p>  
            }/>
            <br/>
            <ImageBlock Reversed={false} BlockImage={RendererSampleImage} BlockImageType='Horizontal' Body={
             <p>
               
             </p>  
            }/>
         </div>
      );
   }
}