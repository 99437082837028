import './Styles/App.css';
import './Styles/Navbar.css';
import React from 'react';

import NavButton from './components/NavButton';
import NavBar from './components/Navbar';

import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import ProjectsList from './pages/ProjectsListPage';
import OtherProjectsPage from './pages/OtherProjectsPage';
import APILandingPage from './pages/APILandingPage';
import NotFoundPage from './pages/NotFoundPage';
import NotFoundProjectPage from './pages/NotFoundProjectPage';
import DefenderPage from './pages/DefenderPage';
import GalanariusPage from './pages/GalanariusPage';
import BlankPage from './pages/BlankPage';
import LinkInBio from './pages/LinkInBioPage';
import TrouterspacePage from './pages/TrouterspacePage';
import CryptidConquestPage from './pages/CryptidConquestPage';
import PlanetariumPage from './pages/PlanetariumPage';
import StarPenguinPage from './pages/StarPenguin';

let appState = 'App Home';

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      path: this.calcPath(),
      subpath: this.calcPath().split('/')[1],
      buttons: {
        homeButton: new NavButton('Home', 'Home', ''),
        aboutButton: new NavButton('About', 'About', 'about'),
        projectsButton: new NavButton('Projects', 'Projects','projects'),
        blogButton: new NavButton('Blog', 'Blog', 'blog'),
      }
    };

    switch(this.state.path.split('/')[0]) {
      case 'home':
        this.state.currentBody = <HomePage/>;
        appState = 'App Home';
        break;
      case 'about':
        this.state.currentBody = <AboutPage/>;
        appState = 'App About';
        break;
      case 'link-in-bio':
        this.state.currentBody = <LinkInBio/>;
        appState = 'App Home';
        break;
      case 'projects':
        if(this.state.subpath == null || this.state.subpath.length === 0) {
          this.state.currentBody = <ProjectsList/>
          break;
        }
        switch(this.state.subpath) {
          case 'planetarium':
            this.state.currentBody = <PlanetariumPage/>
            break;
          case 'star-penguin':
            this.state.currentBody = <StarPenguinPage/>
            break;
          case 'cryptid-conquest':
            this.state.currentBody = <CryptidConquestPage/>;
            break;
          case 'alpha-in-trouterspace':
            this.state.currentBody = <TrouterspacePage/>;
            break;
          case 'other':
            this.state.currentBody = <OtherProjectsPage/>;
            break;
          // TODO: Move to be subpages of other
          case 'defender':
            this.state.currentBody = <DefenderPage/>;
            break;
          case 'galanarius':
            this.state.currentBody = <GalanariusPage/>;
            break;
          default:
            this.state.currentBody = <NotFoundProjectPage/>;
            break;
        }
        appState = 'App Project';
        break;
      case 'insta':
      case 'instagram':
        this.state.currentBody = <BlankPage/>;
        window.location.href = "https://www.instagram.com/jonah.d20";
        break;
      case 'github':
        this.state.currentBody = <BlankPage/>;
        window.location.href = "https://github.com/Queejon";
        break;
      case 'linkedin':
        this.state.currentBody = <BlankPage/>;
        window.location.href = "https://www.linkedin.com/in/jonah-mcconnell/";
        break;
      case 'patreon':
        this.state.currentBody = <BlankPage/>;
        window.location.href = "https://www.patreon.com/queejon";
        break;
      case 'contact':
      case 'email':
      case 'mail':
        this.state.currentBody = <BlankPage/>;
        window.location.href = "mailto:contact@firehead.dev";
        break;
      case 'resume':
        this.state.currentBody = <BlankPage/>;
        window.location.href = "https://jonahmc-bucket.s3.amazonaws.com/Downloads/JonahMcConnellResume.pdf";
        break;
      case 'api':
        this.state.currentBody = <APILandingPage/>;
        break;
      case 'not-found':
      case '404':
      default:
        this.state.currentBody = <NotFoundPage/>;
        break;
    }
  }

  render() {
    return (
      <div id="gradient_bg" className={appState}>
        <NavBar/>
        <div className="App-body">
          {this.state.currentBody}
        </div>
      </div>);
  }

  calcPath() {
    if(window.location.pathname === '/') {
      return 'home';
    }
    return window.location.pathname.substring(1);
  }
}
