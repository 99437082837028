import '../Styles/App.css';
import React from 'react';

export default class BlankPage extends React.Component {
   render() {
      return (
         <div className="Page-container">

         </div>
      );
   }
}