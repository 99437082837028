import React from 'react';
import '../Styles/About.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faGithub, faLinkedin} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faFileLines } from '@fortawesome/free-regular-svg-icons';
faInstagram.iconName = 'instagram';
faGithub.iconName = 'github';
faLinkedin.iconName = 'linkedin';
faEnvelope.iconName = 'email';

export default class AboutPage extends React.Component {
   getSize(){
      if(window.innerWidth < 679){
         return '1x';
      }
      return '1x';
   }

   render() {
      return (
         <div className='Page-container'>
            <div className='About-body'>
               <div className='About-title-decoration'>
                  <span>Hi, I'm Jonah McConnell</span>
                  <span className='About-icon-container'>
                     <span className='About-intro'>Contact Me:</span>
                     <a className='About-icon' href={window.location.origin+'/contact'}><FontAwesomeIcon icon={faEnvelope} size={this.getSize()} color='white'/></a>
                     <a className='About-icon' href={window.location.origin+'/github'}><FontAwesomeIcon icon={faGithub} size={this.getSize()} color='white'/></a>
                     <a className='About-icon' href={window.location.origin+'/linkedin'}><FontAwesomeIcon icon={faLinkedin} size={this.getSize()} color='white'/></a>
                     <a className='About-icon' href={window.location.origin+'/resume'} target='blank' download='Resume'><FontAwesomeIcon icon={faFileLines} size={this.getSize()} color='white'/></a>
                  </span>
               </div>
               <br/>
               <div className='About-body-decoration'>
                  <span className='About-body-intro-wrapper'>
                        <h3 className='About-body-intro'>A little about me:</h3>
                  </span>
                  <p className='About-body-main'>
                     Hi there, my name is Jonah McConnell, and I am currently pursuing Game Development at Full Sail
                     University.
                     <br/><br/>
                     I have lived in the Dallas area most of my life, though have recently made the move to Orlando,
                     Florida to pursue Game Development here at Full Sail. I was incredibly blessed and grateful to be the
                     recipient of Full Sail’s Emerging Technology Scholarship. This is what has made my pursuits possible
                     here. The criteria of my grades and test scores, my ability to program a small game prototype using
                     the Unity Engine prior to attending Full Sail, and my participation in the whiteboard problem solving
                     contest, proved that I am both someone who’s worked hard to get ahead in the past, and will continue
                     to do so during my time at Full Sail and beyond. This scholarship is a huge opportunity for me;
                     however, I find it important to remind myself that the award’s singular purpose is ensure I do not
                     have to worry monetarily about the cost of Full Sail itself. The scholarship has no bearing on my
                     results in classes, nor what I produce from them. Thus, I must redouble my efforts to continue to
                     better myself to improve my skills. No amount of previous effort justifies letting off the gas in my
                     endless craving of new knowledge about programming and computing as a whole.
                     <br/><br/>
                     When it comes to programming, I have been learning for about 7 years now. In that time, I’ve made it a
                     point to explore many applications of my programming skills from basic software, to chatbots, web
                     development, and of course games. My story, like most programmers, is one of continuous practice and
                     experimentation to learn all that I can about my craft. Since the creation of this website, I’ve been
                     documenting my projects, by talking about my process in creating them, what I learned during that time,
                     and what I can take with me going forward based on those things. These notes are written out here on
                     my website in order to document my own growth as a programmer, as well as to showcase any work that I
                     feel shows my capabilities as a programmer.
                     <br/><br/>
                     My goals going forward is to master everything I can while here at Full Sail. I want to not only excel
                     in my classes, but go beyond and work on extra projects, learn extraneous skills, and bolster my
                     portfolio and capabilities to step into the industry confidently. That said, I will enter the industry
                     regardless of my relative success at Full Sail with a continual learning mindset. Regardless of the
                     work I put in now, I know there is always more I can, and want to learn from my Seniors. I always want
                     to be learning, so to facilitate that currently, I will challenge myself with a variety of outside
                     programming projects, ranging from game prototypes to engine or software tools, to standalone software,
                     and more. My hope is that as I continue to learn more, I will find greater complexity and scope to be
                     able to tackle within a constant timeframe. In this way, whether in school, or the industry, I will
                     continue to improve, and learn.

                  </p>
               </div>
            </div>
         </div>
      );
   }
}