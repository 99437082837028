import '../Styles/App.css';
import React from 'react';

export default class NavButton extends React.Component {
   constructor(props, text, path) {
      super(props);
      this.state = {
         isActive: true,
         text: text,
         redirectPath: path,
      };
   }

   render() {
      return (
         <div className="App-header-button">
            <a href={window.location.origin + "/" + this.state.redirectPath} className="App-header-button-link">{this.state.text}</a>
         </div>
      );
   }
}
