import '../Styles/App.css';
import React from 'react';

export default class APILandingPage extends React.Component {
   render() {
      return (
         <div className="Page-container">
            <h1>API</h1>
            <p>Hey there, this isn't quite ready yet. Check back soon for some progress though!</p>
         </div>
      );
   }
}