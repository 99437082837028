import '../Styles/App.css';
import React from 'react';
import ProjectsPage from '../components/ProjectEmbed';
import Iframe from 'react-iframe';

var width = window.innerWidth*.8;
var height = window.innerHeight;
export default class DefenderPage extends React.Component {
   render() {
      return (
         <ProjectsPage name="defender" description={
            <div className="Page-content-block-wide">
              Defender is an Asteroids-like project of mine. It's a simple game where you have to destroy all the asteroids you can before they destroy all your ships. The game has a functioning spawn system which continously spawns new asteroids at an increasing rate, with powerups to match their pace. Requiring only basic animation and art allowed me to focus more on features and game logic, thereby polishing the things within the realm of my strong suite.
            </div>
         }
         embed={
            <Iframe url="https://jonahmc-bucket.s3.amazonaws.com/Defender+WebGL/index.html"
            width= {width+"px"}
            height={height+"px"}
            id="frameID"
            className="frameClass"
            display="initial"
            position="relative"
            allowFullScreen="yes"/> 
         }/>
      );
   }
}