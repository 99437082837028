import '../Styles/App.css';
import TextBlock from '../components/TextBlock';
import ImageBlock from '../components/ImageBlock';
import PlayerImage from '../images/planetarium/Gameplay.png';
import InventoryImage from '../images/planetarium/Inventory.png';
import InventoryStatsImage from '../images/planetarium/InventoryStats.png';
import SkillTreeImage from '../images/planetarium/SkillTree.png'
import React from 'react';

export default class PlanetariumPage extends React.Component {
   render() {
      return (
         <div className="Page-container Project">
            <TextBlock
            Header={
               <span>
                  Planetarium
               </span>
            } 
            Body={
               <span>
                  Looter Shooter: Unreal Engine 5 & Perforce
               </span>
            }/>
            <br/>
            <ImageBlock Reversed={false} BlockImage={InventoryImage} BlockImageType='Horizontal' Body={
             <p>
               The equipment system I made allow dependencies to interact only with the single parent class, while we made numerous child classes for all of the weapons and utilities we added. The equipment system also allowed for swapping out abilities at run time based on save data, and equipping stat modifiers to change the stats of the player in real time.
             </p>  
            }/>
            <br/>
            <ImageBlock Reversed={true} BlockImage={SkillTreeImage} BlockImageType='Horizontal' Body={
             <p>
               When building the skill tree, I focused on making it extensible for potential future changes, and to be as usable as possible for non-programmers. The skill tree validates itself before trying to generate, and supports storing Stat changes, ability unlocks, and storing flags to indicate functional changes for the abilities. I also created procedural UI to display from the generated tree. 
             </p>  
            }/>
            <br/>
            <ImageBlock Reversed={false} BlockImage={InventoryStatsImage} BlockImageType='Horizontal' Body={
             <p>
               When generating loot, our system uses variable rarity chances to pick a rarity, and corresponding number of stats to change. The number of stats and rarities is all exposed to the editor, along with a data asset type to specify the parameters for a logistics curve defining what values the stats can generate on a per stat basis. This gave us control at a very fine level over how our stats were generated, along with an online graph tool to visualize the ranges. 
             </p>  
            }/>
            <br/>
            <ImageBlock Reversed={true} BlockImage={PlayerImage} BlockImageType='Horizontal' Body={
             <p>
               The third person player was composed of numerous components to ensure that we could keep our dependencies indirect where possible. This allowed us to avoid circular dependencies, and even reuse some of our logic in other places of the code base like our enemies. I also worked to extend an existing animation blueprint from the Paragon set of assets to add blending for our attacks, dodging, and using velocity to determine which animation blendspace to play from. 
             </p>  
            }/>
         </div>
      );
   }
}