import '../Styles/App.css';
import '../Styles/Home.css';
import PlanetariumImage from '../images/planetarium/Gameplay.png';
import CryptidConquestImage from '../images/cryptid-conquest/Gameplay.png';
import React from 'react';

export default class HomePage extends React.Component {
   constructor(props) {
      super(props);

      this.redirect = this.redirect.bind(this);

      this.redirectPlanetarium = this.redirectPlanetarium.bind(this);
      this.redirectCryptidConquest = this.redirectCryptidConquest.bind(this);
   }

   redirect(location){
      window.location.href = window.origin + location;
   }

   redirectPlanetarium(){
      this.redirect("/projects/planetarium");
   }

   redirectCryptidConquest(){
      this.redirect("/projects/cryptid-conquest");
   }

   render() {
      return (
         <div className="Page-container Home">
            <div className="Page-content">
               <h1>Welcome</h1>
               <div className="Home-Recent-Posts">
                  <h3>Featured Projects</h3>
                  <div onClick={this.redirectPlanetarium} className="Home-Recent-Post">
                     <h1 className="Home-Recent-Post-Title">Planetarium</h1>
                     <span className="Home-Recent-Post-Collection">
                        <p className="Home-Recent-Post-Description">
                           Full Sail Capstone project in Unreal Engine 5, programmed in C++ with source control through Perforce.
                        </p>
                        <img src={PlanetariumImage} alt='Planetarium Icon' className='Block-Image Horizontal Home'/>
                     </span>
                  </div>
                  <div onClick={this.redirectCryptidConquest} className="Home-Recent-Post">
                     <h1 className="Home-Recent-Post-Title">Cryptid Conquest</h1>
                     <span className="Home-Recent-Post-Collection">
                        <p className="Home-Recent-Post-Description">
                           Full Sail Midterm project in Unity, programmed in C#, with source control through Github.
                        </p>
                        <img src={CryptidConquestImage} alt='Cryptid Conquest Icon' className='Block-Image Horizontal Home'/>
                     </span>
                  </div>
                  <div className="Home-Recent-Posts-Highlight"/>
               </div>
            </div>
         </div>
      );
   }
}