import '../Styles/App.css';
import React from 'react';
import ProjectsPage from '../components/ProjectEmbed';
import Iframe from 'react-iframe';

var width = window.innerWidth*.8;
var height = window.innerHeight;
export default class CryptidConquestPage extends React.Component {
   render() {
      return (
         <ProjectsPage name="cryptid-conquest" description={
            <div className="Page-content-block-wide">
              Cryptid Conquest is the product of my team and my efforts during our midterm project while at Full Sail Univeristy.
              An important note is that the above browser version of the game is very laggy, and does not allow for the greatest experience of the game unfortunately. To download a zipped version of the windows installer, and a smattering of reference media: <a href="https://fso-lms4-mortal-assets.s3.us-east-1.amazonaws.com/222708/2023_5_27/4c985dc3-c2cf-4c2f-a075-5023e9e0a5c8/DapperYetis_CryptidConquest_Final.zip?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIARE7PEONU35M5ITYW%2F20240126%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240126T164250Z&X-Amz-Expires=3600&X-Amz-Signature=804dbcaa63b7fe71d339bc648cb2c849dfa9f2bb307aab394cdf2f3629b85b00&X-Amz-SignedHeaders=host&response-content-disposition=attachment%3B%20filename%3D%22DapperYetis_CryptidConquest_Final.zip%22&x-id=GetObject" className="Project-list-link">click here</a>
              <br/><br/>
              We attempted to make a roguelike game in the vein of Risk of Rain 2, but have it be more fantasy themed. Trying to make enough content to fill a game like this proved to be a big challenge which cost us more features than I would've liked. However in part due to that, I can say with certainty that I learned a lot from this project without a shadow of a doubt.
              <br/><br/>
              Working on this game was a huge lesson in time management and organizing a team effort. Starting with the time management, while there was certainly some of the moment to moment management, my growth was focused more on the macro level, with my expectations for what I could achieve. I learned to expect to not always be working at my absolute best. There's only so long you can burn at 110%. Learning to work around the amount time I could give good, focused work, rather than more mediocre unfocused contributions, turned out to be a huge learning oppurtunity for me. The team effort was a similar oppurtunity to learn. I've worked on quite a few Unity prototypes and projects up to this point, including a recent game jam experience. However, none of these projects saw extended collaboration with my peers like this did. Learning to not only assess what was doable in a time frame for myself but also my teammates was something that I benefited greatly from learning. Over the course of 2 months, with no real options to extend the timetables, we gave everything we could. This meant a lot of practice communicating under stress, and doing what I could to ease pain points for the rest of the team.
              <br/><br/>
              All in all this was a fantastic experience in which we made a great game for the time frame and collective experience we had. While many things were more challenging than expected, the way I rose and grew to match these challenges, and the lessons I learned throughout the project were more than worth the time spent and more.
            </div>
         }
         embed={
            <Iframe url="https://jonahmc-bucket.s3.amazonaws.com/Dapper+Yetis+Midterm+WebGL/index.html"
            width= {width+"px"}
            height={height+"px"}
            id="frameID"
            className="frameClass"
            display="initial"
            position="relative"
            allowFullScreen="yes"/> 
         }/>
      );
   }
}