/* eslint-disable jsx-a11y/img-redundant-alt */
import '../Styles/App.css'
import React from 'react';

export default class ImageBlock extends React.Component {
   render() {
      return (
         <span className='Block-Wrapper'>
            <span className={'Block-Body ' + (this.props.Reversed ? 'Hidden' : 'NotHidden')}>
               {this.props.Body}
            </span>
            <img src={this.props.BlockImage} alt='Block Image' className={'Block-Image ' + this.props.BlockImageType}/>
            <span className={'Block-Body ' + (this.props.Reversed ? 'NotHidden' : 'Hidden')}>
               {this.props.Body}
            </span>
         </span>
      );
   }
}